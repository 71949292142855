import styles from "./index.module.scss"
import { cloudflareLoader } from "/lib/services/helpers/cloudflareLoader";

const KumkumadiLowerBannerComponent = ({ isMobileType }) => {
    return (
        <div className={styles.kumkumadiLowerBannerParent}>
            <img className={styles.kumkumadiLowerBanner}
                loading="lazy"
                src={cloudflareLoader({
                    src: isMobileType ? '/images/kumkumadi/mobile/LowerBanner.jpg' : '/images/kumkumadi/desktop/LowerBanner.jpg',
                    width: isMobileType ? '992' : '1900',
                    quality: 80,
                })}
                alt="Original Ayurveda-based Recipe
                Powered By Saffron" />
        </div>
    )
};
export default KumkumadiLowerBannerComponent;