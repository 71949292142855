import { cloudflareLoader } from "/lib/services/helpers/cloudflareLoader";
import classes from './index.module.scss'

const LaunchBanner = ({isMobileType, image, mobileImage, heading, subHeading }) => {
    return (
        <div className={classes.teaserBannerParent}>
            <img className={classes.teaserBanner}
                loading="eager"
                src={cloudflareLoader({
                    src: isMobileType ? mobileImage : image,
                    width: isMobileType ? '992' : '1900',
                    quality: 80,
                })}
                alt="Launch Teaser" />
            <div className={classes.teaserBannerText}>
                <p className={classes.teaserBannerHead}>{heading}</p>
                <p className={classes.teaserBannerDate}>{subHeading}</p>
            </div>
    </div>
    )
}

export default LaunchBanner;