import Banner from "./Banner";
import { HeadingSection } from "./HeadingSection";
import ProductCarousel from "./ProductCarousel";
import CategoryCarousel from './CategoryCarousel'
import HydrationProduct from "/components/Hydration/HydrationProduct";
import AmarrupaProduct from "/components/Amarrupa/AmarrupaProduct";
import KumkumadiCollectionProduct from "../../KumkumadiCollection/KumkumadiCollectionProduct";
import KumkumadiVideo from "../../KumkumadiCollection/KumkumadiVideo";
import KumkumadiLowerBannerComponent from "../../KumkumadiCollection/KumkumadiLowerBanner";
import LaunchStayTune from "/components/LaunchPage/LaunchStayTune";
import LaunchBanner from "/components/LaunchPage/LaunchBanner";
import LaunchProductCollection from "/components/LaunchPage/LaunchProductCollection";

const componentMap = {
	'banner-carousel': Banner,
	'heading': HeadingSection,
	'category-carousel': CategoryCarousel,
	'product-carousel': ProductCarousel,
	'product-carousel-image': HydrationProduct,
	'product-collection': AmarrupaProduct,
	'product-collection-new': KumkumadiCollectionProduct,
	'lower-banner': KumkumadiLowerBannerComponent,
	'video-section': KumkumadiVideo,
	'stay-tune-banner': LaunchStayTune,
	'banner': LaunchBanner,
	'teaser-collection': LaunchProductCollection,
}

const HomepageJSONComponent = (props) => {
	const Comp = componentMap[props.type];

	if (Comp) {
		return <Comp {...props} />
	}

	return null
}

const HomepageSection = ({ isMobileType, cmsData, identifier='' }) => {
	return (
		<>
			{cmsData?.map((section, key) => {
				return <HomepageJSONComponent isMobileType={isMobileType} identifier={identifier} {...section} key={key} index={key} />
			})}
		</>
	)
}

export default HomepageSection