import { cloudflareLoader } from "/lib/services/helpers/cloudflareLoader";
import classes from './index.module.scss'

const LaunchStayTune = ({isMobileType,image,mobileImage}) => {

    return (
        <div className={classes.teaserBannerParent}>
            <img className={classes.teaserBanner}
                loading="eager"
                src={cloudflareLoader({
                    src: isMobileType ? mobileImage : image,
                    width: isMobileType ? '992' : '1900',
                    quality: 80,
                })}
                alt="Launch Teaser" />
            <div className={classes.teaserBannerText}>
                <p className={classes.teaserBannerHead}>STAY TUNED</p>
                <p className={classes.teaserBannerDate}>01.04.25</p>
            </div>
        </div>
    )
}

export default LaunchStayTune;