import styles from './index.module.scss';
import BuyNowButton from '/components/EyeSerum/BuyNowButton';
import Link from 'next/link';
import { cloudflareLoader } from '/lib/services/helpers/cloudflareLoader';

const imageData = [
    {
        imageURL: '/images/amarrupa/desktop/5.png',
        mobileImageURL: '/images/amarrupa/mobile/4.webp',
        alt: 'Eye contour renewal'
    },
    {
        imageURL: '/images/amarrupa/desktop/6.png',
        mobileImageURL: '/images/amarrupa/mobile/5.webp',
        alt: 'Amarrupa Wrinkle Repair'
    },
    {
        imageURL: '/images/amarrupa/desktop/7.png',
        mobileImageURL: '/images/amarrupa/mobile/6.webp',
        alt: 'Kansa wand'
    },
    {
        imageURL: '/images/amarrupa/desktop/8.png',
        mobileImageURL: '/images/amarrupa/mobile/7.webp',
        alt: 'Amarrupa Contour Defining'
    }
]

const AmarrupaProduct = ({ isMobileType, productsArr }) => {
    return (
        <>
            {!isMobileType && (
                <img
                    src={cloudflareLoader({
                        src: '/images/amarrupa/desktop/4.webp',
                        width: isMobileType ? '992' : '1900',
                        quality: 80,
                    })}
                    alt="Age Defying Power of Amarrupa" />
            )}
            <div className={styles.productsCollection}>
                {productsArr.map((product, index) => {
                    return (
                        <div className={styles.productCollection}>
                            <Link prefetch={false} href={product?.url}>
                                <img
                                    src={cloudflareLoader({
                                        src: isMobileType ? imageData[index]?.mobileImageURL : imageData[index]?.imageURL,
                                        width: isMobileType ? '992' : '1900',
                                        quality: 80,
                                    })}
                                    alt={`${imageData[index]?.alt}`} />
                            </Link>
                            <BuyNowButton index={index} className='amarrupaBtn' buttonText="Add To Cart" pageId="amarrupa-immersive-page" sku={product?.sku} />
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default AmarrupaProduct;