import styles from './index.module.scss';
import BuyNowButton from '/components/EyeSerum/BuyNowButton';
import Link from 'next/link';
import { cloudflareLoader } from '/lib/services/helpers/cloudflareLoader';

const imageDataAM = [
    {
        title: "A.M Collection",
        imageURL: '/images/hydration/new/desktop/Option2/11.jpg',
        mobileImageURL: '/images/hydration/new/mobile/Option2/10.jpg',
        imageClass: 'productAM1'
    },
    {
        title: "A.M Collection",
        imageURL: '/images/hydration/new/desktop/Option2/12.jpg',
        mobileImageURL: '/images/hydration/new/mobile/Option2/11.jpg',
        imageClass: 'productAM2'
    }
]

const imageDataPM = [
    {
        title: "P.M Collection",
        imageURL: '/images/hydration/new/desktop/Option2/14.jpg',
        mobileImageURL: '/images/hydration/new/mobile/Option2/12.jpg',
        imageClass: 'productPM1'
    },
    {
        title: "P.M Collection",
        imageURL: '/images/hydration/new/desktop/Option2/15.jpg',
        mobileImageURL: '/images/hydration/new/mobile/Option2/13.jpg',
        imageClass: 'productPM2'
    }
]

const HydrationProduct = ({ isMobileType, title, productsArr }) => {
    return (
        <div>
            <div className={styles.productHydrationHeading}>
                {title == 'A.M Collection' ? (
                    <img
                        src={cloudflareLoader({
                            src: isMobileType ? '/images/hydration/new/mobile/Option2/9.jpg' : '/images/hydration/new/desktop/Option2/9.jpg',
                            width: isMobileType ? '992' : '1900',
                            quality: 75,
                        })}
                        alt='Advanced Hydration Ritual' />
                ) : (
                    !isMobileType && (
                        <img
                            src={cloudflareLoader({
                                src: '/images/hydration/new/desktop/Option2/13.jpg',
                                width: isMobileType ? '992' : '1900',
                                quality: 75,
                            })}
                            alt='Advanced Hydration Ritual' />
                    )
                )}
            </div>

            <div className={styles.productHydration}>
                {title == 'A.M Collection' ? (
                    <div className={styles.amCollection}>
                        {productsArr?.map((product, index) => {
                            return (
                                <div>
                                    <Link prefetch={false} href={product?.url}>
                                        <img className={`${styles.imgAMCollection} ${styles[imageDataAM[index]?.imageClass]}`}
                                            src={cloudflareLoader({
                                                src: isMobileType ? imageDataAM[index]?.mobileImageURL : imageDataAM[index]?.imageURL,
                                                width: isMobileType ? '992' : '1900',
                                                quality: 80,
                                            })}
                                            alt={`AM Collection${index}`} />
                                    </Link>
                                    <BuyNowButton className='amCollectionBtn' buttonText="Shop Now" pageId="hydration-am-collection" sku={product?.sku} />
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div className={styles.pmCollection}>
                        {productsArr?.map((product, index) => {
                            return (
                                <div>
                                    <Link prefetch={false} href={product?.url}>
                                        <img className={`${styles.imgPMCollection} ${styles[imageDataPM[index]?.imageClass]}`}
                                            src={cloudflareLoader({
                                                src: isMobileType ? imageDataPM[index]?.mobileImageURL : imageDataPM[index]?.imageURL,
                                                width: isMobileType ? '992' : '1900',
                                                quality: 80,
                                            })}
                                            alt={`PM Collection${index}`} />
                                    </Link>
                                    <BuyNowButton className='pmCollectionBtn' buttonText="Shop Now" pageId="hydration-pm-collection" sku={product?.sku} />
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default HydrationProduct;