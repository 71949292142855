import React from "react";

interface HeadingSectionProps {
  description?: string;
}

export const HeadingSection: React.FC<HeadingSectionProps> = (props) => {
  return (
    <div className="container">
      <h1 style={{ marginTop: '30px', textTransform: 'uppercase' }}>{props?.description}</h1>
    </div>
  );
};
