import styles from './index.module.scss';
import BuyNowButton from '/components/EyeSerum/BuyNowButton';
import Link from 'next/link';
import { cloudflareLoader } from '/lib/services/helpers/cloudflareLoader';
import { useRouter } from 'next/router';
import desk_bag_white from '~/public/images/desk-bag-white.inline.svg';

const imageData = [
    {
        headingText: 'NIGHT REPLENISHING GLOW DUO',
        headingIcon: '/images/kumkumadi/Amara.png',
        headingSubText: 'For dull, dehydrated skin & lack of luminusity',
        imageURL: '/images/kumkumadi/desktop/Product1.jpg',
        mobileImageURL: '/images/kumkumadi/mobile/Product1.jpg',
    },
    {
        headingText: 'DAILY SKIN PERFECTING DUO',
        headingIcon: '/images/kumkumadi/Amara.png',
        headingSubText: 'Day time duo for dull dehydrated skin',
        imageURL: '/images/kumkumadi/desktop/Product2.jpg',
        mobileImageURL: '/images/kumkumadi/mobile/Product2.jpg',
    },
    {
        headingText: 'SKIN REFINING DUO',
        headingIcon: '/images/kumkumadi/Amara.png',
        headingSubText: 'Weekly ritual duo for uneven skin texture & lack of glow',
        imageURL: '/images/kumkumadi/desktop/Product3.jpg',
        mobileImageURL: '/images/kumkumadi/mobile/Product3.jpg',
    },
    {
        headingText: 'DAILY SKIN PERFECTING DUO',
        headingIcon: '/images/kumkumadi/Amara.png',
        headingSubText: 'Day time duo for dull dehydrated skin',
        imageURL: '/images/kumkumadi/desktop/Product4.jpg',
        mobileImageURL: '/images/kumkumadi/mobile/Product4.jpg',
    }
]

const KumkumadiCollectionProduct = ({ isMobileType, productsArr }) => {
    const router = useRouter();


    return (
        <>
            <div className={styles.productsCollection}>
                {productsArr.map((product, index) => {
                    return (
                        <div className={styles.productCollection}>
                            <Link prefetch={false} href={product?.url}>
                                <img
                                    loading='lazy'
                                    className={`kumkumadiProductImage${index}`}
                                    src={cloudflareLoader({
                                        src: isMobileType ? imageData[index]?.mobileImageURL : imageData[index]?.imageURL,
                                        width: isMobileType ? '992' : '1900',
                                        quality: 80,
                                    })}
                                    alt={`${imageData[index]?.headingText} ${imageData[index]?.headingSubText}`} />
                            </Link>
                            {product?.sku ? (
                                <BuyNowButton index={index} className='kumkumadiBtn' buttonText="ADD TO CART" pageId="amarrupa-immersive-page" sku={product?.sku} />
                            ) : (
                                <button type="button" onClick={() => router.push(product?.url)} className={styles.buyNowKumkumadi}>
                                    <img src={desk_bag_white} alt="bag icon" width="16" height={16} />
                                    Buy Now</button>
                            )}
                        </div>
                    )
                })}
            </div>

        </>
    )
}

export default KumkumadiCollectionProduct