import { Carousel as PCarousel } from "react-responsive-carousel";
import Link from "next/link";
import Image from "/components/CustomImage";
import styles from "/components/Home/Home.module.css";
import style from "/styles/multi-category-home-page.module.scss";
interface BannerProps {
    imagesArr: {
        url: string;
        src: string;
        alt: string;
        mobilesrc?: string;
    }[];
}

const GAtrack = async (link: string, index: string) => {
    const gaModule = await import('/lib/ga');
    gaModule.event({
        action: link,
        params: {
            "event_label": `webloader_click_position_${index}`,
            "event_category": 'homepage webloaders',
            "value": index,
        }
    });
}

const Banner: React.FC<BannerProps> = (props) => {
    if (props.imagesArr.length > 1) {
        return (
            <div className={`swipe ${styles['swipe']} ${style.banner}`}>
                <PCarousel dynamicHeight showThumbs={false} preventMovementUntilSwipeScrollTolerance={true} interval={5000} autoPlay showStatus={false} stopOnHover emulateTouch={true} infiniteLoop>
                    {props.imagesArr.map((item, index) => (
                        <div className={styles.home_mobile_banner} key={index}>
                            <span>
                                <Link href={item.url} onClick={() => GAtrack(`${item.url}`, `${index + 1}`)}>

                                    <>
                                        <div className={style.desktop}>
                                            {item.src.includes(".mp4") ? (
                                                <video width="2800" autoPlay loop muted playsInline>
                                                    <source src={item.src} type="video/mp4" />
                                                    <source src="movie.ogg" type="video/ogg" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <Image loading="eager" priority={true} src={item.src} placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" alt={item.alt} width={1400} height={400} layout="responsive" />
                                            )}
                                        </div>
                                        <div className={style.mobile}>
                                            {item.mobilesrc.includes(".mp4") ? (
                                                <video width="440" data-wf-ignore="true" data-object-fit="cover" autoPlay loop muted playsInline>
                                                    <source src={item.mobilesrc} type="video/mp4" data-wf-ignore="true" />
                                                </video>
                                            ) : (
                                                <Image loading="eager" priority={true} src={item.mobilesrc} placeholder="blur" blurDataURL="/images/kama-placeholder.jpg" layout="responsive" alt={item.alt} width={440} height={600} />
                                            )}
                                        </div>
                                    </>

                                </Link>
                            </span>
                        </div>
                    ))}
                </PCarousel>
            </div>
        );
    }

    return (
        <div className={`swipe ${styles['swipe']} ${style.banner}`}>
            {props.imagesArr.map((item, index) => (
                <div className={styles.home_mobile_banner} key={index}>
                    <span>
                        <Link href={item.url} onClick={() => GAtrack(`${item.url}`, `${index + 1}`)}>

                            <>
                                <div className={style.desktop}>
                                    {
                                        item.src.includes(".mp4") ? (
                                            <video width="2800" autoPlay loop muted playsInline>
                                                <source src={item.src} type="video/mp4" />
                                                <source src="movie.ogg" type="video/ogg" />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) : (
                                            <Image loading="eager" priority={true} src={item.src} placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" alt={item.alt} width={1400} height={400} layout="responsive" />
                                        )
                                    }
                                </div>
                                <div className={style.mobile}>
                                    {item.mobilesrc.includes(".mp4") ? (
                                        <video width="440" data-wf-ignore="true" data-object-fit="cover" playsInline autoPlay muted loop>
                                            <source src={item.mobilesrc} type="video/mp4" data-wf-ignore="true" />
                                        </video>
                                    ) : (
                                        <Image loading="eager" priority={true} src={item.mobilesrc} placeholder="blur" blurDataURL="/images/kama-placeholder.jpg" alt={item.alt} layout="responsive" width={440} height={600} />
                                    )
                                    }
                                </div>
                            </>

                        </Link>
                    </span>
                </div>
            ))}
        </div>
    );
}

export default Banner;
