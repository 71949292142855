import { useInView } from 'react-intersection-observer';
import styles from './index.module.scss'
import dynamic from 'next/dynamic';
const KumkumadiVideoComponent = dynamic(() => import('./KumkumadiVideoComp'))

const KumkumadiVideo = (props) => {
    const { ref: kumkumadiVideo, inView: kumkumadiVideoView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    });

    return (
        <div ref={kumkumadiVideo} className={styles.VideoSectionContainer}>
            {kumkumadiVideoView ? <KumkumadiVideoComponent {...props} /> : null}
        </div>
    )
};
export default KumkumadiVideo;
