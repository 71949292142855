import Image from "/components/CustomImage";
import styles from '/components/Home/Home.module.css';
import Carousel from "react-multi-carousel";
import { responsive } from '/lib/services/helpers/responsive';
import style from '/styles/multi-category-home-page.module.scss'

interface IndexProps {
    title: string;
    productsArr: {
        url: string;
        src: string;
        alt: string;
        title: string;
    }[];
}

const Index: React.FC<IndexProps> = (props: any) => {

    return (
        <div className={`container ${style.categorycontainer}`}>
            <div className={`home_mobile_sec1 ${styles.home_mobile_sec1} ${styles.home_mobile_sec121}`}>
                <h2 style={{ textTransform: 'uppercase' }}>{props.title}</h2>
            </div>
            <div className={styles.sliderContainer}>
                <Carousel
                    className={styles.categoryList}
                    swipeable={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    partialVisible={true}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    additionalTransfrom={-15}
                    itemClass={styles.carousel_item_padding}
                    minimumTouchDrag={50}
                >
                    {props.productsArr?.map?.((item, index) => (
                        <a key={index} href={`#${item.url}`}>
                            <div className={style.desktop}>
                                <img src={item.src} loading='lazy' alt={item.alt} title={item.alt}
                                    placeholder="blur" />
                            </div>
                            <div className={style.mobile}>
                                <img src={item.mobilesrc} loading='lazy' alt={item.alt} title={item.alt}
                                    placeholder="blur" />
                            </div>
                            <h5>{item.title}</h5>
                        </a>
                    ))}
                </Carousel>
            </div>
        </div>
    );
}

export default Index;
